import React, { FunctionComponent } from 'react'
import { MenuItem, VerticalMenu } from '../../../shared/VerticalMenu'
import { generateApiDocMenuItems } from '../spec-helpers'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  pathTags: string[]
  pathsByTags: Record<string, OpenAPIV3_1.PathItemObject>
  name: string
  webhookTags: string[]
  webhookByTags: Record<string, OpenAPIV3_1.PathItemObject>
}

export const ApiDocMenu: FunctionComponent<Props> = ({ pathTags, pathsByTags, name, webhookTags, webhookByTags }) => {
  const pathMenuItems: MenuItem[] = generateApiDocMenuItems(name, pathTags, pathsByTags)
  const webhookMenuItems: MenuItem[] = generateApiDocMenuItems(name, webhookTags, webhookByTags)

  return (
    <div>
      <div className="breadcrumbs">
        <span>API</span>
        <span>{name}</span>
      </div>

      <VerticalMenu menuItems={[...pathMenuItems, ...webhookMenuItems]} />
    </div>
  )
}
