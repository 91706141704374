import React, { FunctionComponent, ReactNode, useState } from 'react'
import { ampli } from '../../../../ampli'

const noOp = () => {}

type Props = {
  label: string
  textToCopy: string
  children?: ReactNode
}

export const ClickToCopySection: FunctionComponent<Props> = ({ label, textToCopy, children }) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyPayload = () => {
    ampli.copyText({ label })

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setIsCopied(true)

        setTimeout(() => {
          setIsCopied(false)
        }, 3000)
      })
      .catch(noOp)
  }

  return (
    <button onClick={copyPayload} aria-label="Copy" data-hovertext={isCopied ? 'Copied!' : 'Click to copy'}>
      {children}
    </button>
  )
}
