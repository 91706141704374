import React from 'react'
import { Navigate, useLocation, useRouteLoaderData } from 'react-router-dom'
import { OAuthClient } from '../../../../types'

export const OAuthClientsIndexRoute = () => {
  const oAuthClients = useRouteLoaderData('oauthClients') as OAuthClient[]
  const { pathname } = useLocation()

  if (oAuthClients.length === 0) {
    return <Navigate to={`${pathname}/create`} replace />
  }

  return <Navigate to={`${pathname}/${oAuthClients[0].id}`} replace />
}
