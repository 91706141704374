import React, { FunctionComponent } from 'react'

type Props = {
  isActive: boolean
}

export const WebhookStateIndicator: FunctionComponent<Props> = ({ isActive }) => {
  const status = isActive ? 'active' : 'not active'
  return <div role="img" aria-label={`Status ${status}`} className={`status ${isActive && 'green animated'}`} />
}
