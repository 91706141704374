import { API_HOST } from '../lib/environment'
import { authenticatedFetchData } from './fetch'
import type { LoaderFunctionArgs } from 'react-router-dom'
import { HttpError } from '@tomra/client-side-http-client'

export const getDocs = () => authenticatedFetchData(`${API_HOST}/docs`).run().catch(handleError)

export const getProduct = ({ params }: LoaderFunctionArgs) =>
  authenticatedFetchData(`${API_HOST}/docs/product/${params.product}`).run()

export const getApiDoc = ({ params }: LoaderFunctionArgs) =>
  authenticatedFetchData(`${API_HOST}/docs/api/${params.api}`).run()

export const getApiProducts = async () => {
  return authenticatedFetchData(`${API_HOST}/products`).run().catch(handleError)
}

const handleError = (error: HttpError) => {
  if (error.status === 403) {
    return null
  }

  throw error
}
