import React, { FunctionComponent } from 'react'

type Props = {
  date: Date
}

export const DateTimeWithUTC: FunctionComponent<Props> = ({ date }) => {
  return (
    <span className="tooltip" data-tip={date.toUTCString()}>
      {date.toLocaleString()}
    </span>
  )
}
