import React, { FunctionComponent, useState } from 'react'
import { ampli } from '../../../../ampli'

type Props = {
  label: string
  value: string | undefined
  description?: string
}

const noOp = () => {}

export const CopyTextField: FunctionComponent<Props> = ({ label, value, description }) => {
  const [copied, setCopied] = useState<boolean>(false)

  ampli.copyText({ label: description || label })

  const copyToClipboard = () => {
    if (value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          setCopied(true)
          setTimeout(() => setCopied(false), 3000)
        })
        .catch(noOp)
    }
  }

  return (
    <div className="copy-text">
      <div id="copyTextLabel" className="label capitalize">
        {label}
      </div>
      <button
        type="button"
        aria-label={`Copy ${label}`}
        onClick={copyToClipboard}
        data-hoverText={copied ? 'Copied!' : 'Click to copy'}
      >
        {value}
      </button>
    </div>
  )
}
