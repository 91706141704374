import React from 'react'
import { NavLink, useLoaderData, useParams } from 'react-router-dom'
import { OAuthClient } from '../../../../types'
import { ListItemLink } from '../common/ListItemLink'

export const OAuthClientList = () => {
  const oAuthClients = useLoaderData() as OAuthClient[]
  const { environment } = useParams()

  const hasReachedMaximumClientNumber = oAuthClients.length === 3

  return (
    <div className="flex flex-col space-y-6">
      {hasReachedMaximumClientNumber && (
        <div className="alert info">You&apos;ve reached the maximum number of {environment} clients.</div>
      )}

      <ul className="card-list">
        {!hasReachedMaximumClientNumber && (
          <li>
            <NavLink to="create">
              <span className="prefix add">Create client</span>
            </NavLink>
          </li>
        )}

        {oAuthClients.map(client => (
          <li key={client.id}>
            <ListItemLink path={client.id} label={client.name} />
          </li>
        ))}
      </ul>
    </div>
  )
}
