import React, { FunctionComponent, ReactNode } from 'react'

type Props = {
  title: string
  icon?: ReactNode
  children?: React.ReactNode
}

export const Notice: FunctionComponent<Props> = ({ title, icon, children }) => {
  return (
    <div className="prose max-w-screen-xl flex flex-col items-center mx-auto">
      {icon ? <div className="animate-shake mb-6">{icon}</div> : null}
      <h1>{title}</h1>
      {children}
    </div>
  )
}
