export const Breakpoints = {
  Tablet: 48,
  Laptop: 64,
  Desktop: 128
}

export const BreakpointQueries = {
  Tablet: `(min-width: ${Breakpoints.Tablet}rem)`,
  Laptop: `(min-width: ${Breakpoints.Laptop}rem)`,
  Desktop: `(min-width: ${Breakpoints.Desktop}rem)`
}
