import React, { FunctionComponent, useState } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { ApiSchemaRecursive } from './ApiSchemaRecursive'

type Props = {
  schema: OpenAPIV3_1.SchemaObject
}

export const ApiSchemaSelector: FunctionComponent<Props> = ({ schema }) => {
  const items = (schema.oneOf as OpenAPIV3_1.SchemaObject[]) || (schema.anyOf as OpenAPIV3_1.SchemaObject[])
  const tabs = items?.map((obj: OpenAPIV3_1.SchemaObject) => obj.title)
  const [selectedTab, setSelectedTab] = useState(tabs?.[0])

  if (!tabs) {
    return null
  }

  const selectedSchema = items.reduce((acc, sc) => {
    if (sc.title === selectedTab) {
      return { ...sc, properties: { ...sc.properties, ...schema.properties } }
    }

    return acc
  }, {}) as OpenAPIV3_1.SchemaObject

  return (
    <>
      <div className="tabs -m-2 mb-2">
        {items.map(tab => {
          const { title, type } = tab

          return (
            <button
              key={`tab-${title}`}
              onClick={() => setSelectedTab(title)}
              className={`p-2 !text-black ${selectedTab === title && 'active bg-fog'}`}
            >
              <span>{title}</span>
              <pre>{type}</pre>
            </button>
          )
        })}
      </div>

      {selectedSchema && (
        <div className="-m-2 p-2 bg-fog">
          <ApiSchemaRecursive level={0} schema={selectedSchema} />
        </div>
      )}
    </>
  )
}
