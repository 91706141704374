import React, { FunctionComponent, useState } from 'react'
import { ErrorBoundary } from '../../../shared/ErrorBoundary'
import { MinusIcon, PlusIcon } from '../../../icons'
import { Code } from '../../../shared/Code'

type Props = {
  title?: string
  example: any
}

export const ApiExample: FunctionComponent<Props> = ({ title, example }) => {
  const [isOpen, setIsOpen] = useState(false)

  const exampleTitle = title && title !== 'default' ? title?.toLowerCase() : 'example'

  if (!example) {
    return null
  }

  return example ? (
    <ErrorBoundary>
      <div className="flex flex-col items-center space-y-6 mt-6">
        <button className="" onClick={() => setIsOpen(!isOpen)}>
          <span className="flex space-x-6 justify-between items-center">
            <span>{isOpen ? `Close ${exampleTitle}` : `View ${exampleTitle}`}</span>

            <span
              className={isOpen ? 'transition transform -rotate-180' : 'transition transform -rotate-0'}
              aria-hidden="true"
            >
              {isOpen ? <MinusIcon width="1.5rem" height="1.5rem" /> : <PlusIcon width="1.5rem" height="1.5rem" />}
            </span>
          </span>
        </button>

        {isOpen && (
          <div key={exampleTitle} className="w-full">
            <Code code={example} />
          </div>
        )}
      </div>
    </ErrorBoundary>
  ) : null
}
