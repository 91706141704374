import React from 'react'
import { Form, useActionData, useNavigate, useNavigation, useParams, useRouteLoaderData } from 'react-router-dom'
import { Account, ActionData } from '../../../../types'

export const CreateOAuthClientForm = () => {
  const { environment } = useParams()
  const navigate = useNavigate()
  const { state, formData } = useNavigation()
  const data = useActionData() as ActionData
  const account = useRouteLoaderData('account') as Account
  const apiKeyPropertyName = environment === 'sandbox' ? 'sandboxApiKey' : 'productionApiKey'
  const hasApiKeyForEnvironment = account && account[apiKeyPropertyName]

  const isWorking = formData && (state === 'submitting' || state === 'loading')

  return (
    <Form method="post">
      <h2 className="text-2xl">Create {environment} OAuth Client</h2>

      {!hasApiKeyForEnvironment ? (
        <div className="alert warning mt-6">
          You need a {environment} API key to create clients for the {environment} environment.
          <br />
          Please contact support.
        </div>
      ) : null}

      <div className="input-field">
        <label htmlFor="clientName" className="label mt-6">
          Name
        </label>
        <input
          id="clientName"
          type="text"
          name="clientName"
          className="input-text w-full"
          required
          autoComplete="off"
        />
      </div>

      {data?.error && !isWorking ? <div className="text-warm-red font-bold">{data?.error}</div> : null}

      <div className="flex justify-end space-x-6 mt-6">
        <button type="button" className="btn" onClick={() => navigate(`/account/${environment}/oauth-clients`)}>
          Cancel
        </button>

        <button type="submit" className="btn primary">
          Create
        </button>
      </div>
    </Form>
  )
}
