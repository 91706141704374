import { Notice } from './Notice'
import { AlertIcon } from '../icons'
import React from 'react'

export const UnknownError = () => {
  return (
    <div className="md:mt-[var(--navigationBarHeight)] py-6">
      <Notice icon={<AlertIcon width="4rem" height="4rem" />} title="Something went wrong">
        <p>Our technical team has been notified.</p>
      </Notice>
    </div>
  )
}
