import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { LockIcon } from '../../../icons'

type Props = {
  securitySchemes: { [key: string]: OpenAPIV3_1.SecuritySchemeObject }
}

export const ApiAuthentication: FunctionComponent<Props> = ({ securitySchemes }) => {
  if (!securitySchemes) {
    return null
  }

  const schemeKeys = Object.keys(securitySchemes)

  return (
    <div className="my-12">
      <h2 id="authentication" className="mb-2 text-2xl">
        Authentication
      </h2>

      <ul className="flex items-center space-x-6">
        {schemeKeys.map((key: string) => {
          const scheme = securitySchemes[key]

          return scheme.type === 'apiKey' ? (
            <li key={`li-apikey`} aria-label="API Key" className="chip bg-fog py-4 flex items-start space-x-2">
              <LockIcon />

              <div>
                <div className="text-lg">API Key {scheme.in}</div>
                <pre className="p-1 inline">{scheme.name}</pre>
              </div>
            </li>
          ) : scheme.type === 'http' ? (
            <li
              key={`li-http-scheme`}
              aria-label={`${scheme.type} ${key}`}
              className="chip bg-fog py-4 flex items-start space-x-2"
            >
              <LockIcon />

              <div>
                <div className="capitalize text-lg">
                  {scheme.type} {key}
                </div>

                {scheme?.bearerFormat && (
                  <span>
                    <span>Bearer format: </span>
                    <pre className="p-1 inline">{scheme.bearerFormat}</pre>
                  </span>
                )}
              </div>
            </li>
          ) : null
        })}
      </ul>
    </div>
  )
}
