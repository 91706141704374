import React, { FunctionComponent, useState } from 'react'
import { CopyTextField } from '../common/CopyTextField'
import { OAuthClient } from '../../../../types'
import { ampli } from '../../../../ampli'

type Props = {
  client: OAuthClient
}

export const OAuthClientSecret: FunctionComponent<Props> = ({ client }) => {
  const [showSecret, setShowSecret] = useState(false)

  return (
    <div className="w-full">
      {showSecret ? (
        <CopyTextField label="Client secret" value={client.secret as string} />
      ) : (
        <>
          <div className="label">Client Secret</div>
          <div className="blurred-field">
            <div aria-label="Client secret">***********************************************</div>
            <button
              type="button"
              onClick={() => {
                if (showSecret) {
                  setShowSecret(false)
                } else {
                  setShowSecret(true)
                  ampli.revealOAuthClientSecret()
                }
              }}
            >
              Reveal
            </button>
          </div>
        </>
      )}
    </div>
  )
}
