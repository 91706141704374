import React from 'react'
import { NavLink, Outlet, useLoaderData, useLocation, useParams } from 'react-router-dom'
import { Account } from '../../../types'
import { ViewTermsAndConditions } from './ViewTermsAndConditions'
import { AlertIcon } from '../../icons'
import { Notice } from '../../shared/Notice'

export const AccountPage = () => {
  const account = useLoaderData() as Account
  const { environment } = useParams()

  const { pathname } = useLocation()
  const currentPath = pathname.split(`/`)?.[3]

  const hasApiLicenses = account.licenses.length > 0

  return hasApiLicenses ? (
    <>
      <div className="py-6">
        <div className="mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-12 gap-6 items-end">
            <div className="col-span-8">
              <h1>Account</h1>
              <div className="text-4xl my-2">{account.accountName}</div>
              <ViewTermsAndConditions />
            </div>

            <div className="col-span-12 md:col-span-4 flex items-center justify-end">
              <div className="card tabs w-full">
                <NavLink to={`/account/sandbox${currentPath ? `/${currentPath}` : ''}`}>Sandbox</NavLink>
                <NavLink to={`/account/production${currentPath ? `/${currentPath}` : ''}`}>Production</NavLink>
              </div>
            </div>

            <div className="col-span-12 md:col-span-4 tabs mt-6">
              <NavLink to={`/account/${environment || 'sandbox'}`}>Overview</NavLink>
              <NavLink to={`/account/${environment || 'sandbox'}/oauth-clients`}>OAuth Clients</NavLink>
              <NavLink to={`/account/${environment || 'sandbox'}/webhooks`}>Webhooks</NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-screen mx-6 md:mx-auto md:max-w-screen-2xl w-full">
        <Outlet />
      </div>
    </>
  ) : (
    <div className="max-w-screen mx-6 md:mx-auto md:max-w-screen-2xl prose py-6">
      <Notice icon={<AlertIcon width="4rem" height="4rem" />} title="Sorry">
        <p className="text-center">
          You don&apos;t have access to the account page.
          <br />
          Please contact your local sales office to request access.
        </p>

        <a href="https://www.tomra.com/en/contact-us" target="_blank" rel="noreferrer" className="font-bold">
          Find your local sales office
        </a>
      </Notice>
    </div>
  )
}
