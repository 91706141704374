import { API_HOST } from '../lib/environment'
import { authenticatedFetchData } from './fetch'

export const getAccount = () => authenticatedFetchData(`${API_HOST}/management`).run()

export const getWebhookHistory = (webhookId: string, environment: string, status?: string, next?: string) => {
  const url = new URL(`${API_HOST}/webhooks/${webhookId}/pagedhistory`)

  url.searchParams.append('environment', environment as string)

  if (next) {
    url.searchParams.append('next', next)
  }

  if (status) {
    url.searchParams.append('status', status.toUpperCase())
  }

  return authenticatedFetchData(url.toString(), {
    method: 'GET'
  }).run()
}

export const retryWebhookDelivery = (webhookId: string, eventId: string, environment: string) => {
  return authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}/event/${eventId}/retry?environment=${environment}`, {
    method: 'POST',
    body: JSON.stringify({})
  }).run()
}

export const retryAllWebhookDeliveries = (webhookId: string, environment: string) => {
  return authenticatedFetchData(`${API_HOST}/webhooks/${webhookId}/event/retry-latest?environment=${environment}`, {
    method: 'POST',
    body: JSON.stringify({})
  }).run()
}
