import { authenticatedFetchData } from '../../../../services/fetch'
import { API_HOST } from '../../../../lib/environment'
import { redirect } from 'react-router-dom'
import type { ActionFunctionArgs, LoaderFunctionArgs } from 'react-router-dom'
import { handleRequestError } from '../../../../lib/httpHelpers'
import { ampli } from '../../../../ampli'

export const createOAuthClientAction = async ({ params, request }: ActionFunctionArgs) => {
  const { environment } = params as { environment: string }

  const formData = await request.formData()
  const clientName = formData.get('clientName')

  const response = await authenticatedFetchData(
    `${API_HOST}/management/auth/client/${clientName}?environment=${environment}`,
    {
      method: 'POST'
    }
  )
    .run()
    .catch(handleRequestError)

  if (response.error) {
    return response
  }

  ampli.addOAuthClient({ environment })

  return redirect(`/account/${environment}/oauth-clients/${response.id}`)
}

export const deleteOAuthClientAction = async ({ params }: LoaderFunctionArgs) => {
  const { environment, clientId } = params as { environment: string; clientId: string }

  await authenticatedFetchData(`${API_HOST}/management/auth/client/${clientId}?environment=${environment}`, {
    method: 'DELETE'
  }).run()

  ampli.deleteOAuthClient({ environment })

  return redirect(`/account/${environment}/oauth-clients`)
}

export const getOAuthClients = ({ params }: LoaderFunctionArgs) =>
  authenticatedFetchData(`${API_HOST}/management/auth/client?environment=${params.environment}`).run()

export const getOAuthClient = ({ params }: LoaderFunctionArgs) =>
  authenticatedFetchData(
    `${API_HOST}/management/auth/client/${params.clientId}?environment=${params.environment}`
  ).run()
