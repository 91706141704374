import React from 'react'
import { WebhooksLoaderData } from '../../../../types'
import { Outlet, useLoaderData } from 'react-router-dom'
import { WebhooksEmptyState } from './WebhooksEmptyState'
import { WebhookList } from './WebhookList'

export const Webhooks = () => {
  const { webhooks } = useLoaderData() as WebhooksLoaderData

  return (
    <>
      {webhooks.length === 0 ? (
        <WebhooksEmptyState />
      ) : (
        <>
          <div className="grid grid-cols-12 gap-6 items-start">
            <div className="col-span-12 md:col-span-4">
              <WebhookList />
            </div>
            <div className="col-span-12 md:col-span-8">
              <Outlet />
            </div>
          </div>
        </>
      )}
    </>
  )
}
