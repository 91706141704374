import React, { FunctionComponent } from 'react'

type Props = {
  node: { children: string[] }
}

export const InlineCodeRenderer: FunctionComponent<Props> = ({ node }) => {
  const text = node.children

  return <code className="before:content-none after:content-none break-all">{text}</code>
}
