import React from 'react'
import { CopyTextField } from './common/CopyTextField'
import { ENVIRONMENT } from '../../../lib/environment'
import { useParams } from 'react-router-dom'

export const ApiUrls = () => {
  const { environment } = useParams()
  const region = ENVIRONMENT.replace(/(.test|.prod)/g, '')
  const environmentUrl = environment === 'sandbox' ? `https://${region}-sandbox` : `https://${region}`

  return (
    <section id="urlSection" className="card p-6 flex flex-col space-y-6">
      <h2 className="text-lg capitalize">{environment} URLs</h2>

      <CopyTextField
        label="API"
        value={`${environmentUrl}.api.developer.tomra.cloud`}
        description={`${environment} API url`}
      />

      <CopyTextField
        label="Auth"
        value={`${environmentUrl}.auth.developer.tomra.cloud`}
        description={`${environment} auth url`}
      />
    </section>
  )
}
