import React from 'react'
import { Notice } from '../shared/Notice'
import { AlertIcon } from '../icons'

export const PageNotFoundPage = () => {
  return (
    <main className="h-full w-full mt-6 mx-auto">
      <Notice title="Uh oh..." icon={<AlertIcon width="4rem" height="4rem" />}>
        <p>We could not find the page you we&apos;re looking for.</p>
      </Notice>
    </main>
  )
}
