/* eslint react/no-children-prop: 0*/
import React, { FunctionComponent } from 'react'
import remarkParse from 'remark-parse'
import rehypeSlug from 'rehype-slug'
import remarkDirective from 'remark-directive'
import remarkGfm from 'remark-gfm'
import remarkAdmonition from './remark-rehype-plugins/remarkAdmonition'
import remarkDirectiveRehype from './remark-rehype-plugins/remarkDirectiveRehype'
import ReactMarkdown from 'react-markdown'
import { QRCodeRenderer } from './renderers/QRCodeRenderer'
import { LinkRenderer } from './renderers/LinkRenderer'
import { ButtonRenderer } from './renderers/ButtonRenderer'
import { ImageRenderer } from './renderers/ImageRenderer'
import { CodeBlockRenderer } from './renderers/CodeBlockRenderer'
import { InlineCodeRenderer } from './renderers/InlineCodeRenderer'
import { Admonition } from './renderers/Admonition'

const components = {
  admonition: (admonition: any) => <Admonition node={admonition} />,
  qr: (qr: any) => <QRCodeRenderer node={qr} />,
  a: (a: any) => <LinkRenderer node={a} />,
  button: (button: any) => <ButtonRenderer node={button} />,
  img: (img: any) => <ImageRenderer node={img} />,
  pre: (pre: any) => <CodeBlockRenderer node={pre.node} />,
  code: (code: any) => <InlineCodeRenderer node={code} />
}

export const MarkdownContent: FunctionComponent<{ markdown: string }> = ({ markdown }) => {
  const remarkPlugins = [remarkParse, remarkGfm, remarkDirective, remarkDirectiveRehype, remarkAdmonition]
  const rehypePlugins = [rehypeSlug]

  return (
    <>
      <ReactMarkdown
        children={markdown}
        remarkPlugins={remarkPlugins as any}
        rehypePlugins={rehypePlugins}
        components={components as any}
        urlTransform={url => url} /* allow all urls */
      />
    </>
  )
}
