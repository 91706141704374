import { logError } from '@tomra/datadog-browser-logging'
import { OpenAPIV3_1 } from 'openapi-types'

export const isOperation = (operationName: string) => operations.some(o => o === operationName)

export const getTags = (paths: OpenAPIV3_1.PathsObject): string[] => {
  if (!paths) {
    return []
  }

  try {
    const pathNames = Object.keys(paths)

    return unique(
      pathNames.flatMap(name => {
        const path = paths[name] as OpenAPIV3_1.PathItemObject

        const operationsWithTags = Object.keys(path)
          .filter(isOperation)
          .filter(operationName => {
            const operation = path[operationName as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.OperationObject
            return operation?.tags
          })

        return operationsWithTags.map(operationName => {
          const operation = path[operationName as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.OperationObject
          const maybeTags = operation?.tags
          // We don't want to group by the 'Beta' tag. It is only needed to show a badge in the ApiDoc UI
          return maybeTags ? maybeTags.filter(tag => tag.toLowerCase() !== 'beta')?.[0] : []
        })
      })
    )
  } catch (e: any) {
    const error = new Error('Error extracting spec tags')
    logError(error, new Error(e))
    throw error
  }
}

export const mapPathsToTags = (
  paths: OpenAPIV3_1.PathsObject<{}> | undefined
): Record<string, OpenAPIV3_1.PathItemObject> => {
  try {
    if (!paths) {
      return {}
    }

    const pathNames = Object.keys(paths)

    let pathsByTags: { [key: string]: OpenAPIV3_1.PathItemObject } = {}

    pathNames.forEach(name => {
      const path = paths[name] as OpenAPIV3_1.PathItemObject
      const operationsForPath = Object.keys(path).filter(isOperation)

      const pathsWithTags = operationsForPath.filter(operationName => {
        const operation = path[operationName as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.OperationObject
        const maybeTags = operation.tags

        // We don't want to group by the 'Beta' tag. It is only needed to show a badge in the ApiDoc UI
        return maybeTags ? maybeTags.filter(tag => tag.toLowerCase() !== 'beta') : []
      })

      const tags = unique(
        pathsWithTags.map(operation => {
          const operationObj = path[operation as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.OperationObject
          const maybeTags = operationObj.tags
          // We don't want to group by the 'Beta' tag. It is only needed to show a badge in the ApiDoc UI
          return maybeTags ? maybeTags.filter(tag => tag.toLowerCase() !== 'beta')?.[0] : []
        })
      )

      if (tags.length > 0) {
        const tag = tags[0]

        const tagIsDefined = Boolean(pathsByTags[tag])
        const content = { [name]: path }

        pathsByTags = {
          ...pathsByTags,
          [tag]: tagIsDefined ? { ...pathsByTags[tag], ...content } : content
        }
      }
    })

    return pathsByTags as Record<string, OpenAPIV3_1.PathItemObject>
  } catch (e: any) {
    const error = new Error('Error mapping paths to tags')
    logError(error, new Error(e))
    throw error
  }
}

export const createIdFromString = (string: string) => {
  const encodedHeading = encodeURIComponent(string.trim().toLowerCase())
  return encodedHeading.replace(/%../g, '-')
}

export const generateApiDocMenuItems = (
  docName: string,
  pathTags: string[],
  pathsByTags: { [key: string]: OpenAPIV3_1.PathItemObject }
) => {
  return pathTags.map(tag => {
    const id = createIdFromString(tag)

    const path = pathsByTags[tag] as OpenAPIV3_1.PathItemObject
    const pathsNames = Object.keys(path)

    return {
      id,
      label: tag,
      path: `/api/${docName}#${id}`,
      children: pathsNames.flatMap(pathName => {
        const operations = path[pathName as keyof OpenAPIV3_1.PathItemObject] as OpenAPIV3_1.OperationObject
        const operationsNames = Object.keys(operations)

        return operationsNames.filter(isOperation).map(operationName => {
          const id = createIdFromString(`${tag}-${operationName}-${pathName}`)

          const operation = operations[
            operationName as keyof OpenAPIV3_1.OperationObject
          ] as OpenAPIV3_1.OperationObject

          const label = operation.summary || operation.operationId || pathName

          return {
            id,
            label,
            operationName,
            path: `/api/${docName}#${id}`,
            children: []
          }
        })
      })
    }
  })
}

const unique = (arr: any[]) => Array.from(new Set([...arr]))

const operations = ['get', 'put', 'post', 'delete', 'options', 'head', 'patch', 'trace']
