import React, { FunctionComponent } from 'react'
import { createPortal } from 'react-dom'

type Props = {
  children?: React.ReactNode
  isOpen: boolean
  variant?: string
}

export const Modal: FunctionComponent<Props> = ({ children, isOpen, variant = 'top' }) => {
  return createPortal(
    <div className={`modal ${variant} ${isOpen && 'open'}`}>{children}</div>,
    document.getElementById('root') as HTMLDivElement
  )
}
