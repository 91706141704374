import React, { FunctionComponent } from 'react'
import { useLoaderData, useLocation } from 'react-router-dom'
import { VerticalMenu } from './VerticalMenu'
import { createMenuFromMarkdown } from '../../lib/markdownHelpers'
import { MarkdownContent } from './MarkdownContent'

type Props = {
  pageTitle?: string
}

export const MarkdownDoc: FunctionComponent<Props> = ({ pageTitle }) => {
  const markdown = useLoaderData() as string
  const location = useLocation()

  const { title, menuItems } = createMenuFromMarkdown(markdown, '')

  const scrollToIdOnRender = () => {
    const [, id] = location.hash.split('#')
    const el = document.getElementById(id)

    if (el) {
      el.scrollIntoView({ behavior: 'smooth' })
    } else {
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className="h-full w-full grid grid-cols-12 gap-6 max-w-screen-2xl mx-auto px-6 2xl:px-0 py-6">
      <div className="hidden lg:block lg:col-span-3">
        <VerticalMenu sitePrefix={pageTitle} menuTitle={title} menuItems={menuItems} />
      </div>

      <div className="col-span-12 lg:col-span-9">
        <div className="mx-auto">
          <div className="prose lg:py-6 scroll-mt-6" ref={scrollToIdOnRender}>
            <MarkdownContent markdown={markdown} />
          </div>
        </div>
      </div>
    </div>
  )
}
