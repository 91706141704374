import { NavLink, useLoaderData } from 'react-router-dom'
import React from 'react'
import { Webhook } from '../../../../types'
import { ListItemLink } from '../common/ListItemLink'

export const WebhookList = () => {
  const { webhooks } = useLoaderData() as { webhooks: Webhook[] }

  return (
    <div>
      <ul className="card-list">
        <li>
          <NavLink to="create">
            <span className="prefix add">Create webhook</span>
          </NavLink>
        </li>

        {webhooks.map(webhook => {
          const { id, url, active } = webhook

          return (
            <li key={id}>
              <ListItemLink path={id as string} label={url} subLabel={active ? 'Active' : 'Inactive'} />
            </li>
          )
        })}
      </ul>
    </div>
  )
}
