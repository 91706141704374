import React, { FunctionComponent } from 'react'
import { NavLink } from 'react-router-dom'

type Props = {
  path: string
  label: string
  subLabel?: string
}

export const ListItemLink: FunctionComponent<Props> = ({ path, label, subLabel }) => {
  return (
    <NavLink to={path}>
      <span className="flex flex-col">
        <span className="block max-w-[17.5rem] truncate">{label}</span>
        {subLabel && <small>{subLabel}</small>}
      </span>
    </NavLink>
  )
}
