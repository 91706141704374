import React from 'react'
import { AlertIcon } from './icons'
import { Notice } from './shared/Notice'

export const RequestAccessPage = () => {
  return (
    <div className="page-container w-full mx-auto max-w-screen-xl grid grid-cols-12 gap-6">
      <div className="col-span-8 col-start-3 lg:col-span-6 lg:col-start-4">
        <Notice icon={<AlertIcon width="4rem" height="4rem" />} title="Sorry">
          <p className="text-center">
            You don&apos;t have access to this page or resource.
            <br />
            Please contact your local sales office to request access.
          </p>

          <a href="https://www.tomra.com/en/contact-us" target="_blank" rel="noreferrer">
            Find your local sales office
          </a>
        </Notice>
      </div>
    </div>
  )
}
