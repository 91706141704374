import React, { FunctionComponent, useState } from 'react'
import { Code } from '../../../shared/Code'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  examples: Record<string, OpenAPIV3_1.ExampleObject>
}

export const ApiExamples: FunctionComponent<Props> = ({ examples }) => {
  const [selectedExample, setSelectedExample] = useState(0)

  const exampleTitles = Object.keys(examples)

  const example = examples[exampleTitles[selectedExample]]

  return exampleTitles.length > 0 ? (
    <div className="bg-bg-color rounded-[var(--card-border-radius)]">
      {exampleTitles.length > 1 ? (
        <div className="p-4 select-field">
          <label htmlFor="example" className="label">
            Examples
          </label>
          <select
            id="example"
            className="w-full"
            defaultValue={exampleTitles[0]}
            onChange={({ target }) => setSelectedExample(parseInt(target.value))}
          >
            {exampleTitles.map((title, i) => (
              <option key={`ex-title-${title}`} value={i}>
                {title}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="p-4">Example</div>
      )}

      <Code code={example?.value || (example as string)} />
    </div>
  ) : null
}
