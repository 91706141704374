import React from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'
import { OAuthClient } from '../../../../types'
import { OAuthClientsEmptyState } from './OAuthClientsEmptyState'
import { OAuthClientList } from './OAuthClientList'

export const OAuthClients = () => {
  const oAuthClients = useLoaderData() as OAuthClient[]

  return (
    <>
      {oAuthClients.length === 0 ? (
        <OAuthClientsEmptyState />
      ) : (
        <>
          <div className="grid grid-cols-12 gap-6 items-start mt-6">
            <div className="col-span-12 md:col-span-4">
              <OAuthClientList />
            </div>
            <div className="col-span-12 md:col-span-8">
              <div className="card p-6">
                <Outlet />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
