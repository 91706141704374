import { storage } from '../../lib/authentication'

const lsKey = 'accepted-tomra-collection-developer-tos'

export const termsLastUpdated = new Date('2022-10-07')

export const acceptedTermsOfService = async (): Promise<Date | null> => {
  const hasAccepted = await storage.getItem(lsKey)
  const termsHaveChanged = new Date(hasAccepted) < termsLastUpdated

  return termsHaveChanged || !hasAccepted ? null : new Date(hasAccepted)
}

export const acceptTermsOfService = () => {
  return storage.setItem(lsKey, new Date().toISOString())
}
