import React, { FunctionComponent, useState } from 'react'
import { RequestStatus, WebhookEventHistoryType } from '../../../../../types'
import { retryWebhookDelivery } from '../../../../../services/account-service'
import { logError } from '@tomra/datadog-browser-logging'
import { RetryIcon } from '../../../../icons/RetryIcon'

export const RetryWebhookDelivery: FunctionComponent<{
  event: WebhookEventHistoryType
  environment: string
  onComplete: () => void
}> = ({ event, environment, onComplete }) => {
  const [requestStatus, setRequestStatus] = useState<RequestStatus>('idle')
  const [error, setError] = useState<string>('')

  const retry = () => {
    setError('')
    setRequestStatus('pending')

    retryWebhookDelivery(event.webhookId, event.eventId, environment)
      .then(onComplete)
      .catch(error => {
        if (error.status === 404) {
          setError('Retry failed. Webhook event not found.')
        } else {
          setError('Retry failed')
          logError(new Error('Unable to retry webhook delivery'), error)
        }

        setRequestStatus('idle')
      })
  }

  if (error) {
    return <div className="pr-4 text-warm-red-dark">{error}</div>
  }

  if (requestStatus === 'pending') {
    return <div className="pr-4">Retrying...</div>
  }

  return (
    <button onClick={retry} className="chip bg-bg-color flex items-center">
      <RetryIcon />
      <span className="!bg-bg-color">Retry</span>
    </button>
  )
}
