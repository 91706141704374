import React, { FunctionComponent, useState } from 'react'
import { JSONTreeView } from '../pages/api-doc/common/JSONTreeView'
import { ampli } from '../../ampli'

const noOp = () => {}

type Props = {
  code: string
}

export const Code: FunctionComponent<Props> = ({ code }) => {
  const [allIsExpanded, setAllIsExpanded] = useState(false)
  const [copied, setCopied] = useState<boolean>(false)

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(JSON.stringify(code))
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 3000)
      })
      .catch(noOp)

    ampli.copyText({ label: 'Code_Copy' })
  }

  function expandAll() {
    setAllIsExpanded(true)
    ampli.buttonClicked({ label: 'Code_ExpandAll' })
  }

  function collapseAll() {
    setAllIsExpanded(false)
    ampli.buttonClicked({ label: 'Code_CollapseAll' })
  }

  return (
    <div className="relative px-4 pb-4">
      <div
        className={`absolute pointer-events-none bg-storm text-white py-2 px-4 rounded-lg top-10 right-4 transition ease-bounce duration-300 ${copied ? 'translate-y-0 opacity-100' : 'translate-y-6 opacity-0'}`}
      >
        Copied!
      </div>

      <div className="flex items-start justify-end space-x-4">
        <button className="chip bg-storm/10" onClick={copyToClipboard}>
          Copy
        </button>
        <button className="chip bg-storm/10" onClick={expandAll}>
          Expand all
        </button>
        <button className="chip bg-storm/10" onClick={collapseAll}>
          Collapse all
        </button>
      </div>

      <JSONTreeView level={0} data={code} expandAll={allIsExpanded} />
    </div>
  )
}
