import React, { useEffect, useState } from 'react'
import { useFetcher, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom'
import { Modal } from '../../../shared/Modal'
import { OAuthClient } from '../../../../types'

export const DeleteOAuthClientConfirmation = () => {
  const { environment, clientId } = useParams()
  const navigate = useNavigate()
  const fetcher = useFetcher()

  const [isOpen, setIsOpen] = useState(false)

  const clients = useRouteLoaderData('oauthClients') as OAuthClient[]
  const client = clients.find(c => c.id === clientId) as OAuthClient

  const isWorking = fetcher.state === 'submitting' || fetcher.state === 'loading'

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 50)
  }, [])

  return (
    <Modal isOpen={isOpen}>
      <div className="dialog center">
        {isWorking ? (
          <div className="flex items-center justify-center">
            <div className="loading-infinity w-20"></div>
          </div>
        ) : (
          <div className="dialog-content">
            <fetcher.Form method="delete" action={`/account/${environment}/oauth-clients/${client.id}/delete`}>
              <h1 className="text-2xl">Delete &quot;{client.name}&quot;?</h1>
              <p>This action cannot be undone.</p>

              <input type="hidden" className="hidden" name="clientId" value={client.id} />

              <div className="flex justify-end space-x-6">
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    setIsOpen(false)
                    navigate(-1)
                  }}
                >
                  No
                </button>
                <button type="submit" className="btn danger">
                  Delete
                </button>
              </div>
            </fetcher.Form>
          </div>
        )}
      </div>
    </Modal>
  )
}
