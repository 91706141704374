import React from 'react'
import { termsLastUpdated } from './termsOfServiceHelper'

export const TermsAndConditions = () => {
  return (
    <div className="py-6 px-6 prose-sm lg:pb-6 lg:prose">
      <small>TOMRA Collection Developer</small>
      <h1 id="termsAndConditionsTitle" className="mb-0">
        Terms and Conditions
      </h1>
      <small className="font-bold">Last updated {termsLastUpdated.toLocaleDateString()}</small>

      <p id="termsAndConditionsDescription">
        These terms (&quot;Terms&quot;) apply between TOMRA Systems ASA (&quot;TOMRA&quot;) and the person or legal
        entity (&quot;You&quot;) that has access to or uses the TOMRA developer platform, including API’s, services,
        data and other content (together the &quot;Developer Portal&quot;)
      </p>
      <p>
        TOMRA is and remains the sole owner of the Developer Portal and associated intellectual property rights
        (&quot;IPR&quot;) and any content developed or produced by TOMRA. You will only be granted a limited, revocable,
        and non-exclusive license to the Development Portal upon payment in full and on the condition that You comply
        with these Terms. Any non-compliance may lead to exclusion from the Developer Portal.
      </p>
      <p>
        TOMRA retains the right to make changes and/or updates to the Developer Portal from time to time, upon its sole
        discretion.
      </p>
      <p>
        The TOMRA Privacy Policy and the TOMRA license terms (as updated from time to time) apply to these Terms and are
        available here:{' '}
        <a href="https://www.tomra.com/en/legal/license" target="_blank" rel="noreferrer">
          TOMRA Software License Terms and Warranty
        </a>
      </p>

      <p>Additional terms may apply for specific services.</p>

      <h2>Terms of use</h2>
      <p>
        You accept that TOMRA may communicate with you through your e-mail address [or through the API of the Developer
        Portal]
      </p>
      <p>
        You are solely responsible for the use and incorporation of the Developer Portal in your own business. You may
        not use the Developer Portal for any activities or programs that, directly or indirectly enables or contributes
        to illegal activities.
      </p>
      <p>
        You are responsible for all activities that occur under Your name and undertake to keep the passwords and other
        authorization credentials confidential. You must ensure appropriate information security policies, standards,
        and procedures in line with best industry practice. Any situation or incident that may potentially impact
        information security shall be handled, documented, and reported to TOMRA as soon as practicable.
      </p>
      <p>
        You shall indemnify TOMRA against all losses, claims, liabilities or damages suffered by TOMRA or for which
        TOMRA will become liable and which arises in relation i) Your infringement of TOMRA’s IPR, ii) third party IPR
        infringement claims related to You’re use of the Developer Portal, iii) non-compliance of Your confidentiality
        and information security obligations, iv) cases of willful misconduct, gross negligence, fraud or fraudulent
        misrepresentation, or v) other circumstances within Your control.
      </p>

      <h2>Confidentiality</h2>
      <p>
        As recipient of confidential information you undertake to keep the Confidential Information secret and
        confidential and not to disclose Confidential Information to any third-party without the prior written consent
        of TOMRA and not to use or exploit the Confidential Information in any way, except to fulfill these Terms.
      </p>
      <p>
        Confidential Information means all material or information that is not publicly known and which by denotation or
        other reasonable circumstances is considered to be confidential, (however recorded or preserved), that is
        disclosed or made available to you through the Developer Portal.
      </p>

      <h2>Disclaimer</h2>
      <p>
        The content, data and services available in the Developer Portal are provided without any guarantees. TOMRA
        shall not be responsible for any claims, losses or damages caused by inaccurate or wrongful data, unauthorized
        access, downtime or break downs of the Developer Portal.
      </p>
      <p>
        Access to the Developer Portal or related assistance shall not be construed as an endorsement by TOMRA of Your
        business.
      </p>

      <h2>Dispute resolution</h2>
      <p>
        These Terms are governed by Norwegian law. Any dispute shall be settled by arbitration in accordance with the
        Rules of the Arbitration and Dispute Resolution Institute of the Oslo Chamber of Commerce in force at any time.
        The Place of Arbitration shall be Oslo, Norway. Any dispute resolution shall be conducted in English, unless
        otherwise agreed between the Parties.
      </p>
    </div>
  )
}
