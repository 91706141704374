import React, { FunctionComponent } from 'react'
import { MarkdownContent } from '../../../shared/MarkdownContent'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  info: OpenAPIV3_1.InfoObject
  openapi: string
}

export const ApiInfo: FunctionComponent<Props> = ({ info, openapi }) => {
  if (!info) {
    return null
  }

  const { title, version, description } = info

  const isBeta = parseInt(version.toString()[0]) === 0

  return (
    <>
      <div className="relative inline-block">
        {isBeta && <span className="absolute -top-2 -right-20 chip bg-fire-orange-lighter">BETA</span>}

        <h1 className="text-3xl" id={title}>
          {title}
        </h1>
      </div>

      {isBeta && (
        <div className="block my-4 chip bg-fire-orange-lighter">
          This API is currently experimental and may undergo changes before becoming stable.
        </div>
      )}

      <div className="flex space-x-4 mt-4">
        <div className="chip bg-fog">API {version}</div>
        <div className="chip bg-fog">OpenAPI {openapi}</div>
      </div>

      {description !== undefined && (
        <div className="prose mt-6">
          <MarkdownContent markdown={description} />
        </div>
      )}
    </>
  )
}
