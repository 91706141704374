import React, { FunctionComponent } from 'react'

type Props = {
  node: { alt: string; src: string }
}

export const ImageRenderer: FunctionComponent<Props> = ({ node }) => {
  const { alt, src } = node
  return <img className="card w-full" src={src} alt={alt} />
}
