import React from 'react'
import { ErrorElement } from '../../../../shared/ErrorElement'
import { useParams, useRouteError } from 'react-router-dom'
import { HttpError } from '@tomra/client-side-http-client'
import { AlertIcon } from '../../../../icons'

export const WebhookHistoryErrorElement = () => {
  const { environment } = useParams()
  const error = useRouteError() as HttpError

  if (error.status === 404 && environment === 'sandbox') {
    return (
      <div className="mt-8 flex items-center space-x-2">
        <AlertIcon />
        <div className=" font-bold text-warm-red">Webhook history is not available</div>
      </div>
    )
  }

  return <ErrorElement />
}
