/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 1
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'developerportaltest';

export const ApiKey: Record<Environment, string> = {
  developerportaltest: 'ffb57daf44adfc239a3841f607c34fac'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '1',
    branch: 'main',
    source: 'web',
    versionId: 'ca7420f8-273a-443f-bec3-5c57a26c2208'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface AddOAuthClientProperties {
  environment: string;
}

export interface AddWebhookProperties {
  environment: string;
}

export interface ButtonClickedProperties {
  label: string;
}

export interface CopyTextProperties {
  label: string;
}

export interface DeleteOAuthClientProperties {
  environment: string;
}

export interface DeleteWebhookProperties {
  environment: string;
}

export interface EditWebhookProperties {
  environment: string;
}

export interface FilterWebhookEventsProperties {
  status: string;
}

export interface OpenApiSpecDownloadedProperties {
  name: string;
}

export interface OpenApiSpecSectionProperties {
  path: string;
}

export class AddOAuthClient implements BaseEvent {
  event_type = 'AddOAuthClient';

  constructor(
    public event_properties: AddOAuthClientProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddWebhook implements BaseEvent {
  event_type = 'AddWebhook';

  constructor(
    public event_properties: AddWebhookProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddWebhookNotificationEmail implements BaseEvent {
  event_type = 'AddWebhookNotificationEmail';
}

export class ButtonClicked implements BaseEvent {
  event_type = 'ButtonClicked';

  constructor(
    public event_properties: ButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CopyText implements BaseEvent {
  event_type = 'CopyText';

  constructor(
    public event_properties: CopyTextProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteOAuthClient implements BaseEvent {
  event_type = 'DeleteOAuthClient';

  constructor(
    public event_properties: DeleteOAuthClientProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteWebhook implements BaseEvent {
  event_type = 'DeleteWebhook';

  constructor(
    public event_properties: DeleteWebhookProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteWebhookNotificationEmail implements BaseEvent {
  event_type = 'DeleteWebhookNotificationEmail';
}

export class EditWebhook implements BaseEvent {
  event_type = 'EditWebhook';

  constructor(
    public event_properties: EditWebhookProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterWebhookEvents implements BaseEvent {
  event_type = 'FilterWebhookEvents';

  constructor(
    public event_properties: FilterWebhookEventsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenApiSpecDownloaded implements BaseEvent {
  event_type = 'OpenAPISpecDownloaded';

  constructor(
    public event_properties: OpenApiSpecDownloadedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenApiSpecSection implements BaseEvent {
  event_type = 'OpenAPISpecSection';

  constructor(
    public event_properties: OpenApiSpecSectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RetryWebhookDelivery implements BaseEvent {
  event_type = 'RetryWebhookDelivery';
}

export class RevealOAuthClientSecret implements BaseEvent {
  event_type = 'RevealOAuthClientSecret';
}

export class ShowWebhookEventPayload implements BaseEvent {
  event_type = 'ShowWebhookEventPayload';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * AddOAuthClient
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/AddOAuthClient)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  addOAuthClient(
    properties: AddOAuthClientProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddOAuthClient(properties), options);
  }

  /**
   * AddWebhook
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/AddWebhook)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  addWebhook(
    properties: AddWebhookProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddWebhook(properties), options);
  }

  /**
   * AddWebhookNotificationEmail
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/AddWebhookNotificationEmail)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  addWebhookNotificationEmail(
    options?: EventOptions,
  ) {
    return this.track(new AddWebhookNotificationEmail(), options);
  }

  /**
   * ButtonClicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/ButtonClicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  buttonClicked(
    properties: ButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ButtonClicked(properties), options);
  }

  /**
   * CopyText
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/CopyText)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. label)
   * @param options Amplitude event options.
   */
  copyText(
    properties: CopyTextProperties,
    options?: EventOptions,
  ) {
    return this.track(new CopyText(properties), options);
  }

  /**
   * DeleteOAuthClient
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/DeleteOAuthClient)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  deleteOAuthClient(
    properties: DeleteOAuthClientProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteOAuthClient(properties), options);
  }

  /**
   * DeleteWebhook
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/DeleteWebhook)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  deleteWebhook(
    properties: DeleteWebhookProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteWebhook(properties), options);
  }

  /**
   * DeleteWebhookNotificationEmail
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/DeleteWebhookNotificationEmail)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  deleteWebhookNotificationEmail(
    options?: EventOptions,
  ) {
    return this.track(new DeleteWebhookNotificationEmail(), options);
  }

  /**
   * EditWebhook
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/EditWebhook)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. environment)
   * @param options Amplitude event options.
   */
  editWebhook(
    properties: EditWebhookProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditWebhook(properties), options);
  }

  /**
   * FilterWebhookEvents
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/FilterWebhookEvents)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  filterWebhookEvents(
    properties: FilterWebhookEventsProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterWebhookEvents(properties), options);
  }

  /**
   * OpenAPISpecDownloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/OpenAPISpecDownloaded)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. name)
   * @param options Amplitude event options.
   */
  openApiSpecDownloaded(
    properties: OpenApiSpecDownloadedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenApiSpecDownloaded(properties), options);
  }

  /**
   * OpenAPISpecSection
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/OpenAPISpecSection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  openApiSpecSection(
    properties: OpenApiSpecSectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new OpenApiSpecSection(properties), options);
  }

  /**
   * RetryWebhookDelivery
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/RetryWebhookDelivery)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  retryWebhookDelivery(
    options?: EventOptions,
  ) {
    return this.track(new RetryWebhookDelivery(), options);
  }

  /**
   * RevealOAuthClientSecret
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/RevealOAuthClientSecret)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  revealOAuthClientSecret(
    options?: EventOptions,
  ) {
    return this.track(new RevealOAuthClientSecret(), options);
  }

  /**
   * ShowWebhookEventPayload
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/tomracollection/Developer%20Portal%20Test/events/main/latest/ShowWebhookEventPayload)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  showWebhookEventPayload(
    options?: EventOptions,
  ) {
    return this.track(new ShowWebhookEventPayload(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
