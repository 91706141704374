import React from 'react'
import { Outlet } from 'react-router-dom'

export const OAuthClientsEmptyState = () => {
  return (
    <div className="grid grid-cols-12 gap-6 items-start mt-6">
      <div className="col-span-6 col-start-4 card p-6">
        <Outlet />
      </div>
    </div>
  )
}
