import React, { FunctionComponent, useEffect, useState } from 'react'
import { Link, NavLink, useNavigation } from 'react-router-dom'
import { AnimatedHamburgerIcon } from '../icons/AnimatedHamburgerIcon'
import { ApiProductsData, RequestStatus } from '../../types'
import tomraArrow from '@tomra/consumer-customer-design-system/config/svg/tomra.svg'

type Props = {
  apiProducts: ApiProductsData
  logoutStatus: RequestStatus
  hasAccess: boolean
  logout: () => void
}

export const SmallScreenNavigation: FunctionComponent<Props> = ({ apiProducts, logoutStatus, hasAccess, logout }) => {
  const { state } = useNavigation()
  const [isOpen, setIsOpen] = useState(false)

  const navLinkClasses = `block p-sm rounded-[var(--card-border-radius)] transition-colors hover:bg-[rgb(255,255,255)]/10`

  useEffect(() => {
    setIsOpen(false)
  }, [state])

  return (
    <>
      <div className="px-4 bg-white h-[3rem] w-full flex items-center justify-between z-[2]">
        <div className="flex items-center">
          <img src={tomraArrow} className="h-8" alt="TOMRA logo" />
          <Link className="!w-auto !pl-4 !pr-0" to="/">
            TOMRA Collection Developer
          </Link>
        </div>

        <button aria-label="Open menu" className="relative btn-icon" onClick={() => setIsOpen(!isOpen)}>
          <AnimatedHamburgerIcon isOpen={isOpen} />
        </button>
      </div>

      <div
        className={`absolute z-[1] bg-white w-full transition ease-[cubic-bezier(.8,.33,0,0.96)] duration-300 ${isOpen ? 'translate-y-[3rem]' : '-translate-y-[130%]'}`}
      >
        {hasAccess && apiProducts ? (
          <div className="p-6 flex flex-col space-y-8">
            <h2 className="text-xl">Getting started</h2>
            <ul className="card-list">
              <li>
                <NavLink to="/">Getting started</NavLink>
              </li>
            </ul>

            <h2 className="text-xl">Products</h2>
            {apiProducts.products.length > 0 ? (
              <ul className="card-list">
                {apiProducts.products.map(product => {
                  const path = `/product/${product.id}`

                  return (
                    <li key={product.id}>
                      <NavLink to={path}>{product.name}</NavLink>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <div className="mb-6 text-yellow">You don&apos;t have access to any products at the moment.</div>
            )}

            <h2 className="text-xl">APIs</h2>
            {apiProducts.apis.length > 0 ? (
              <ul className="card-list">
                {apiProducts.apis.map(api => {
                  const path = `/api/${api.id}`

                  return (
                    <li key={api.id}>
                      <NavLink to={path}>{api.name}</NavLink>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <div className="mb-6 text-yellow">You don&apos;t have access to any APIs at the moment.</div>
            )}
          </div>
        ) : null}

        <div className="p-6">
          <ul className="card-list">
            <li>
              <NavLink to="/account" className={navLinkClasses}>
                Account
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="flex items-center justify-center mb-6">
          <button className="btn danger" disabled={logoutStatus === 'pending'} onClick={logout}>
            {logoutStatus === 'pending' ? (
              <span role="progressbar" className="inline-block loading-infinity w-6 h-full mt-1" />
            ) : (
              'Log out'
            )}
          </button>
        </div>
      </div>
    </>
  )
}
