import React from 'react'
import { WebhookForm } from './WebhookForm'
import { useNavigate, useParams, useRouteLoaderData } from 'react-router-dom'
import { Webhook, WebhooksLoaderData } from '../../../../types'

export const EditWebhook = () => {
  const { environment, webhookId } = useParams()
  const navigate = useNavigate()

  const { webhooks } = useRouteLoaderData('webhooks') as WebhooksLoaderData

  const webhook = webhooks.find(w => w.id === webhookId) as Webhook

  return (
    <div className="card p-6">
      <h2 className="text-2xl mb-6">Update {environment} webhook</h2>

      <WebhookForm
        formMethod="patch"
        formAction={`/account/${environment}/webhooks/${webhookId}/edit`}
        webhook={webhook}
        onCancel={() => navigate(-1)}
      />
    </div>
  )
}
