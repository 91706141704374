import React, { FunctionComponent, useState } from 'react'
import { MarkdownContent } from '../../../shared/MarkdownContent'
import { OpenAPIV3_1 } from 'openapi-types'
import { MinusIcon, PlusIcon } from '../../../icons'
import { AnimatePresence, motion } from 'framer-motion'
import { ApiExample } from './ApiExample'
import { expandAnimation } from '../../../shared/animationVariants'

type Props = {
  schema: OpenAPIV3_1.SchemaObject
  name?: string
  children?: React.ReactNode
}

export const ApiSchemaItem: FunctionComponent<Props> = ({ schema, name, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { type, format, required, description, example, title, deprecated } = schema

  const supportedProperties = [
    'type',
    'title',
    'format',
    'required',
    'description',
    'example',
    'oneOf',
    'allOf',
    'anyOf',
    'enum',
    'properties',
    'items',
    'deprecated'
  ]
  const unSupportedProperties = Object.keys(schema).filter(key => supportedProperties.indexOf(key) === -1)

  const enumValues = schema.enum

  const bgColor = isOpen ? 'bg-fog' : 'bg-bg-color'

  const ButtonOrDivContainer: FunctionComponent<{ children?: React.ReactNode }> = props => {
    return children ? (
      <div
        className={`${bgColor} p-4 ${isOpen ? 'rounded-t-[var(--card-border-radius)]' : 'rounded-[var(--card-border-radius)]'}`}
      >
        <button
          aria-label={`Expand ${name || title} ${format || type}`}
          className="w-full text-left"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="flex items-start justify-between">
            {props.children}

            {isOpen ? <MinusIcon width="1.5rem" height="1.5rem" /> : <PlusIcon width="1.5rem" height="1.5rem" />}
          </span>
        </button>
      </div>
    ) : (
      <div className={`flex justify-between ${bgColor} p-4 rounded-[var(--card-border-radius)]`}>{props.children}</div>
    )
  }

  return (
    <li
      aria-label={name || title}
      className={`[&:not(:first-child)]:my-6 [&:last-child]:mb-0 border-2 ${
        isOpen ? 'border-fog rounded-[var(--card-border-radius)]' : 'border-transparent'
      }`}
    >
      <ButtonOrDivContainer>
        <div className="m-0 flex flex-col space-y-2">
          <div className="flex flex-wrap items-center space-x-2">
            {(name || title) && (
              <pre className={`${deprecated ? 'line-through' : ''}`}>
                {name || <span className="capitalize">{title}</span>}
              </pre>
            )}

            {schema.oneOf ? (
              <pre className="p-1 code">
                oneOf: {schema.oneOf.map((obj: OpenAPIV3_1.SchemaObject) => obj.title).join(' | ')}
              </pre>
            ) : schema.anyOf ? (
              <pre className="p-1 code">
                anyOf: {schema.anyOf.map((obj: OpenAPIV3_1.SchemaObject) => obj.title).join(' | ')}
              </pre>
            ) : (
              <pre className="p-1 code">
                {format ? format : type === 'array' ? `${format || type}[]` : title ? title : type}
              </pre>
            )}

            {deprecated && <div className="chip bg-fire-orange-lighter">deprecated</div>}

            {required && <div className="text-sm text-warm-red">required</div>}
          </div>

          {description && (
            <div className="prose">
              <MarkdownContent markdown={description} />
            </div>
          )}

          {enumValues && (
            <div>
              <div className="mr-1 mb-2">Possible enum values:</div>
              <div className="flex flex-col items-start space-y-2">
                {enumValues.map((enumVal: string) => (
                  <pre
                    key={`enum-${enumVal}`}
                    className="p-1 text-sm font-bold bg-fog rounded-[var(--card-border-radius)] inline"
                  >
                    {enumVal}
                  </pre>
                ))}
              </div>
            </div>
          )}

          {unSupportedProperties.length > 0 ? (
            <div className="flex flex-col space-y-2">
              {unSupportedProperties.map(key => (
                <>
                  <pre key={`unsupported-${key}`}>
                    {key}: {JSON.stringify(schema[key as keyof OpenAPIV3_1.BaseSchemaObject], null, 2)}
                  </pre>
                </>
              ))}
            </div>
          ) : null}

          {!children && example ? (
            <div className="flex items-center">
              <span className="mr-xs">Example:</span>
              <pre className="p-xs text-sm font-bold bg-fog rounded-half inline">{example}</pre>
            </div>
          ) : null}
        </div>
      </ButtonOrDivContainer>

      <div className={`${isOpen ? 'rounded-b-[var(--card-border-radius)] p-2' : ''}`}>
        <AnimatePresence>
          {isOpen ? (
            <motion.div {...expandAnimation}>
              {children}

              <ApiExample example={example} />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </li>
  )
}
