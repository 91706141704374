import React, { isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { logError } from '@tomra/datadog-browser-logging'

export const ErrorElement = () => {
  const maybeRouteError: any = useRouteError()
  const error = isRouteErrorResponse(maybeRouteError) ? 'Something went wrong' : maybeRouteError

  if (maybeRouteError) {
    logError(new Error('Error caught in ErrorElement'), new Error(maybeRouteError))
  }

  return (
    <div className="font-bold text-warm-red-dark">
      {error.body?.detail || error.body?.title || 'Something went wrong'}
    </div>
  )
}
