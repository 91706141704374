import React from 'react'

export const WebhookEventSkeleton = () => (
  <div className="flex space-x-4 items-center justify-between">
    <div className="flex items-center space-x-4">
      <div className="skeleton-circle w-6 h-6"></div>
      <div className="skeleton-paragraph w-64"></div>
    </div>
    <div className="flex flex-col space-y-2 items-end">
      <div className="skeleton-paragraph w-36"></div>
      <div className="skeleton-paragraph w-16"></div>
    </div>
  </div>
)
