import React, { FunctionComponent, useRef, useState } from 'react'
import { ActionData, Webhook, WebhooksLoaderData } from '../../../../types'
import { Form, useActionData, useNavigation, useRouteLoaderData } from 'react-router-dom'
import { WebhookSecretDialog } from './WebhookSecretDialog'

type Props = {
  formMethod: 'patch' | 'post'
  formAction: string
  webhook: Webhook
  onCancel: () => void
}

export const WebhookForm: FunctionComponent<Props> = ({ webhook, formAction, formMethod, onCancel }) => {
  const { state, formData } = useNavigation()
  const { webhookEventTypes } = useRouteLoaderData('webhooks') as WebhooksLoaderData
  const data = useActionData() as ActionData

  const checkboxRef = useRef<HTMLInputElement>(null)

  const isWorking = formData && (state === 'submitting' || state === 'loading')

  const webhookCreated = Boolean(data?.success?.secret)

  const [selectedEvents, setSelectedEvents] = useState(webhook?.events || [])

  function toggleAllEventTypes() {
    setSelectedEvents(selectedEvents.length === webhookEventTypes.length ? [] : webhookEventTypes)
  }

  function toggleEventType(webhookEventType: string) {
    if (selectedEvents.includes(webhookEventType)) {
      setSelectedEvents(selectedEvents.filter(event => event !== webhookEventType))
    } else {
      setSelectedEvents([...selectedEvents, webhookEventType])
    }
  }

  return (
    <>
      <Form method={formMethod} action={formAction} className=" grid grid-cols-6 gap-y-8">
        <div className="input-field col-span-6">
          <label htmlFor="url" className="label">
            URL
          </label>
          <input
            id="url"
            type="url"
            name="url"
            className="input-text w-full"
            required
            defaultValue={webhook?.url || 'https://'}
          />
        </div>

        <div className="col-span-4 mr-4 col-start-1 flex items-center justify-between">
          <div className="flex flex-col">
            <div className="label">Active</div>
            <small className="ml-4">The webhook must be active in order to receive events</small>
          </div>

          <label htmlFor="active" className="label">
            <span className="sr-only">Active</span>
            <div className="toggle">
              <input
                id="active"
                type="checkbox"
                name="active"
                defaultChecked={webhook ? Boolean(webhook?.active) : true}
              />
              <span className="toggle-switch"></span>
            </div>
          </label>
        </div>

        <fieldset className="col-span-3 col-start-1 flex flex-col space-y-sm">
          <legend className="label mb-sm">Select events to listen to</legend>

          <ul className="checkbox-list pl-4 mt-2">
            <li>
              <input
                ref={checkboxRef}
                id="allEvents"
                className={`checkbox ${selectedEvents.length > 0 && selectedEvents.length < webhookEventTypes.length && 'indeterminate'}`}
                type="checkbox"
                checked={selectedEvents.length === webhookEventTypes.length}
                onChange={toggleAllEventTypes}
              />
              <label htmlFor="allEvents">All events</label>
            </li>
            <li>
              <ul>
                {webhookEventTypes.map(webhookEventType => {
                  return (
                    <li key={webhookEventType}>
                      <input
                        id={webhookEventType}
                        className="checkbox"
                        type="checkbox"
                        name="events"
                        value={webhookEventType}
                        checked={selectedEvents.includes(webhookEventType)}
                        onChange={() => toggleEventType(webhookEventType)}
                      />
                      <label htmlFor={webhookEventType}>{webhookEventType}</label>
                    </li>
                  )
                })}
              </ul>
            </li>
          </ul>
        </fieldset>

        {data?.error && !isWorking ? <div className="text-warm-red font-bold">{data?.error}</div> : null}

        <div className="col-span-6 flex justify-end space-x-6">
          <button type="button" className="btn" onClick={onCancel}>
            Cancel
          </button>

          <button type="submit" className="btn primary flex items-center justify-center">
            {isWorking ? <div className="loading-infinity" /> : 'Save'}
          </button>
        </div>
      </Form>

      {webhookCreated ? <WebhookSecretDialog secret={data.success.secret} onClose={onCancel} /> : null}
    </>
  )
}
