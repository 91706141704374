import React from 'react'
import { WebhookForm } from './WebhookForm'
import { useNavigate, useParams } from 'react-router-dom'

export const CreateWebhook = () => {
  const { environment } = useParams()
  const navigate = useNavigate()

  const webhook = { url: 'https://', events: [], active: true }

  return (
    <div className="card p-6">
      <h2 className="text-2xl mb-6">Create {environment} webhook</h2>

      <WebhookForm
        webhook={webhook}
        formMethod="post"
        formAction={`/account/${environment}/webhooks/create`}
        onCancel={() => navigate(-1)}
      />
    </div>
  )
}
