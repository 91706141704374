import React, { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { Notice } from './Notice'
import { AlertIcon } from '../icons'

export const UnknownErrorWithReload: FunctionComponent<{ reloadPath: string }> = ({ reloadPath }) => {
  const navigate = useNavigate()

  return (
    <div className="md:mt-[var(--navigationBarHeight)]">
      <Notice icon={<AlertIcon width="4rem" height="4rem" />} title="Something went wrong">
        <p>Our technical team has been notified.</p>
        <button className="btn" onClick={() => navigate(reloadPath)}>
          Reload
        </button>
      </Notice>
    </div>
  )
}
