import { logError } from '@tomra/datadog-browser-logging'

export const handleRequestError = (error: any) => {
  if (error.status >= 500) {
    logError(new Error('Unexpected error: '), error)
    throw error
  }

  return { error: error.body?.detail || error.body?.title || 'Something went wrong' }
}
