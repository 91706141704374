import { fetchData } from '@tomra/client-side-http-client'
import { authStore } from '../lib/authentication'

export const authenticatedFetchData = (url: string, options: RequestInit = {}) => {
  return fetchData(
    url,
    { ...options },
    {
      fetchNewToken: authStore.fetchNewToken,
      getToken: authStore.getToken,
      retryCount: 0,
      timeoutInMs: 240000
    }
  )
}
