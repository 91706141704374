import React from 'react'
import { APIKeys } from './APIKeys'
import { ApiUrls } from './ApiUrls'

export const AccountOverview = () => {
  return (
    <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
      <div className="flex-1">
        <ApiUrls />
      </div>
      <div className="flex-1">
        <APIKeys />
      </div>
    </div>
  )
}
