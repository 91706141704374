import React, { FunctionComponent } from 'react'

type Props = {
  node: { children: any[] }
}

export const CodeBlockRenderer: FunctionComponent<Props> = ({ node }) => {
  const code = node.children[0].children[0].value

  return (
    <div className="not-prose codeblock">
      <pre>
        <code>{code}</code>
      </pre>
    </div>
  )
}
