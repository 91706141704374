import { Link, NavLink } from 'react-router-dom'
import React, { FunctionComponent } from 'react'
import { ApiProductsData, RequestStatus } from '../../types'
import { LogoutIcon } from '../icons'
import tomraArrow from '@tomra/consumer-customer-design-system/config/svg/tomra.svg'

type Props = {
  apiProducts: ApiProductsData
  logoutStatus: RequestStatus
  hasAccess: boolean
  logout: () => void
}

export const LargeScreenNavigation: FunctionComponent<Props> = ({ apiProducts, hasAccess, logoutStatus, logout }) => {
  return (
    <nav className="menu">
      <ul className="px-6 2xl:px-0" style={{ gridTemplateColumns: 'repeat(14, minmax(0, 1fr))' }}>
        <li className="!col-[1_/_span_3]">
          <img src={tomraArrow} className="h-8 -mr-2" alt="TOMRA logo" />
          <Link to="/" className="text-xl">
            TOMRA Collection Developer
          </Link>
        </li>

        {hasAccess && apiProducts ? (
          <>
            <li>
              <NavLink to="/">Getting started</NavLink>
            </li>
            <li>
              <NavLink to="/product" onClick={e => e.preventDefault()}>
                Product
              </NavLink>
              <ul>
                {apiProducts.products.length > 0 ? (
                  apiProducts.products.map(product => (
                    <li key={product.id}>
                      <NavLink to={`/product/${product.id}`}>{product.name}</NavLink>
                    </li>
                  ))
                ) : (
                  <li>You don&apos;t have access to any products at the moment.</li>
                )}
              </ul>
            </li>
            <li>
              <NavLink to="/apis" onClick={e => e.preventDefault()}>
                APIs
              </NavLink>
              <ul>
                {apiProducts.apis.length > 0 ? (
                  apiProducts.apis.map(api => (
                    <li key={api.id}>
                      <NavLink to={`/api/${api.id}`}>{api.name}</NavLink>
                    </li>
                  ))
                ) : (
                  <li>You don&apos;t have access to any APIs at the moment.</li>
                )}
              </ul>
            </li>
            <li>
              <NavLink to="/account">Account</NavLink>
            </li>
          </>
        ) : null}

        <li className="flex items-center justify-end !col-[14_/_span_1]">
          <button aria-label="Log out" disabled={logoutStatus === 'pending'} onClick={logout}>
            {logoutStatus === 'pending' ? (
              <span role="progressbar" className="loading-infinity w-8" />
            ) : (
              <span className="flex items-center justify-end space-x-4">
                <LogoutIcon width="2rem" height="2rem" />
              </span>
            )}
          </button>
        </li>
      </ul>
    </nav>
  )
}
