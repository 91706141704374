import React, { FunctionComponent, useState } from 'react'
import { Modal } from '../../../shared/Modal'
import { CopyTextField } from '../common/CopyTextField'

const noOp = () => {}

type Props = {
  secret: string
  onClose: () => void
}

export const WebhookSecretDialog: FunctionComponent<Props> = ({ secret, onClose }) => {
  const [safelyStored, setSafelyStored] = useState<boolean>(false)

  return (
    <Modal isOpen={true}>
      <div className="dialog">
        <div className="card p-6 flex flex-col space-y-xl">
          <div className="prose">
            <h1 id="webhookDialogTitle">Webhook secret</h1>
            <p id="webhookDialogDescription" className="mb-6">
              This secret is needed to confirm the authenticity of the webhook message.
            </p>
          </div>

          <CopyTextField value={secret} description="Webhook secret" label="Webhook secret" />

          <p className="prose py-4">
            <strong>This secret will never be shown again, so it is important that you store it somewhere safe!</strong>
          </p>
        </div>

        <div className="card-footer flex justify-between">
          <div className="flex items-center space-x-2">
            <input
              id="safelyStored"
              type="checkbox"
              className="checkbox"
              checked={safelyStored}
              onChange={() => setSafelyStored(!safelyStored)}
            />
            <label htmlFor="safelyStored">I have safely stored this</label>
          </div>

          <button
            className="btn"
            aria-disabled={!safelyStored}
            disabled={!safelyStored}
            onClick={!safelyStored ? noOp : onClose}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )
}
