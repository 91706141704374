import React, { FunctionComponent, useId, useState } from 'react'
import { Webhook, WebhookEventHistoryType } from '../../../../../types'
import { useParams, useRouteLoaderData } from 'react-router-dom'
import { ClickToCopySection } from '../../common/ClickToCopySection'
import { CopyIcon } from '../../../../icons'
import { RetryWebhookDelivery } from './RetryWebhookDelivery'
import { AnimatePresence, motion } from 'framer-motion'
import { expandAnimation } from '../../../../shared/animationVariants'
import { ampli } from '../../../../../ampli'
import { DateTimeWithUTC } from '../../../../shared/DateTimeWithUTC'

const webhookStatusMap: { [key: string]: string } = {
  DELIVERED: 'Delivered',
  RETRYING: 'Retrying',
  FAILED: 'Failed',
  USER_INITIATED_RETRY: 'User triggered retry'
}

const webhookStatusDetailMap: { [key: string]: string } = {
  HTTP_TIMEOUT: 'HTTP timeout',
  HTTP_OTHER: 'Other error',
  UNKNOWN_HOST: 'Invalid hostname',
  MAX_RETRIES: 'Max. retries'
}

const getReadableWebhookStatusDetail = (statusDetail: string) => {
  if (webhookStatusDetailMap[statusDetail]) {
    return webhookStatusDetailMap[statusDetail].toLowerCase()
  }

  if (statusDetail.includes('HTTP_CODE_')) {
    const [, httpCode] = statusDetail.split('HTTP_CODE_')
    return `http code ${httpCode}`
  }

  return statusDetail.toLowerCase()
}

type Props = {
  event: WebhookEventHistoryType
}

export const WebhookHistoryEvent: FunctionComponent<Props> = ({ event }) => {
  const { events } = useRouteLoaderData('webhook') as Webhook
  const { eventId, eventType, payload, status, statusDetail, created } = event
  const { environment } = useParams() as { environment: string; webhookId: string }
  const [optimisticStatus, setOptimisticStatus] = useState<WebhookEventHistoryType['status'] | 'USER_INITIATED_RETRY'>(
    status
  )
  const [showEventPayload, setShowEventPayload] = useState(false)
  const [copyText, setCopyText] = useState('Click to copy event id')

  const accordionTitleId = useId()
  const accordionContentId = useId()

  const isDelivered = optimisticStatus === 'DELIVERED'
  const isFailed = optimisticStatus === 'FAILED'
  const isRetrying = optimisticStatus === 'RETRYING'
  const userTriggeredRetry = optimisticStatus === 'USER_INITIATED_RETRY'

  const mappedStatus = webhookStatusMap[optimisticStatus] || optimisticStatus

  const canRetry = optimisticStatus === 'FAILED' && statusDetail !== 'MAX_RETRIES'

  function copyEventId() {
    navigator.clipboard.writeText(eventId).then(() => {
      setCopyText('Copied!')
      setTimeout(() => {
        setCopyText('Click to copy event id')
      }, 5000)
    })
  }

  return (
    <li className="py-4">
      <div className="grid grid-cols-12 items-center gap-x-6">
        <div className="sr-only">Event {status.toLowerCase()}</div>
        <div className="col-span-7 text-lg pl-4">
          <span className="sr-only">Last updated at:</span>
          <DateTimeWithUTC date={new Date(created)} />
        </div>

        <div
          className={`col-span-7 rounded-[var(--card-border-radius)] pl-4 flex items-center ${isFailed ? '!bg-warm-red/5' : isDelivered ? '!bg-mint-green/10' : 'bg-bg-color'}`}
        >
          <div className={`status ${isFailed ? 'red' : isDelivered ? 'green' : 'pulse'}`}></div>
          <div className="copy-text w-full">
            <button
              className="!bg-transparent text-left !text-sm"
              aria-labelledby="copyTextLabel"
              data-hoverText={copyText}
              onClick={copyEventId}
            >
              {eventId}
            </button>
          </div>
        </div>

        <div className="col-span-5 flex flex-col items-end space-y-2">
          {events.length > 1 && (
            <div className="font-mono text-sm pr-4">
              <span className="sr-only">Event type:</span>
              {eventType}
            </div>
          )}

          <button
            id={accordionTitleId}
            aria-label={`${showEventPayload ? 'Hide' : 'Show'} payload for event ID: ${eventId}`}
            aria-expanded={showEventPayload}
            aria-controls={accordionContentId}
            className="chip bg-bg-color"
            onClick={() => {
              if (showEventPayload) {
                setShowEventPayload(false)
              } else {
                ampli.showWebhookEventPayload()
                setShowEventPayload(true)
              }
            }}
          >
            <span className="prefix add">{showEventPayload ? 'Hide payload' : 'Show payload'}</span>
          </button>
        </div>

        <div className="col-span-7 pl-4 flex items-start justify-between mt-2">
          {userTriggeredRetry ? (
            <span>Retry triggered by user</span>
          ) : isFailed ? (
            <div>
              <span className="text-warm-red-dark">{mappedStatus}</span>
              {!userTriggeredRetry && (
                <span className="text-warm-red-dark"> due to {getReadableWebhookStatusDetail(statusDetail)}</span>
              )}
            </div>
          ) : isRetrying ? (
            <span>Retrying...</span>
          ) : null}

          {canRetry && (
            <RetryWebhookDelivery
              event={event}
              environment={environment}
              onComplete={() => {
                ampli.retryWebhookDelivery()
                setOptimisticStatus('USER_INITIATED_RETRY')
              }}
            />
          )}
        </div>
      </div>

      <div id={accordionContentId} role="region" aria-labelledby={accordionTitleId} className="mt-6">
        <AnimatePresence key={`payload-${eventId}`}>
          {showEventPayload && (
            <motion.div className="codeblock" {...expandAnimation}>
              <pre>
                <code>{JSON.stringify(JSON.parse(payload), null, 2)}</code>
              </pre>
              <ClickToCopySection label="Webhook event payload" textToCopy={JSON.stringify(JSON.parse(payload))}>
                <CopyIcon width="1.5rem" height="1.5rem" />
              </ClickToCopySection>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </li>
  )
}
