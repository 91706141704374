import React from 'react'
import { CopyTextField } from './common/CopyTextField'
import { Account } from '../../../types'
import { Link, useParams, useRouteLoaderData } from 'react-router-dom'

export const APIKeys = () => {
  const { environment } = useParams()
  const account = useRouteLoaderData('account') as Account
  const apiKey = environment === 'sandbox' ? account.sandboxApiKey : account.productionApiKey

  return (
    <section id="apiKeySection" className="flex flex-col space-y-6 card p-6">
      <h2 className="text-lg capitalize">API Keys</h2>

      {apiKey ? (
        <div>
          <CopyTextField label={`${environment} API Key`} value={apiKey} description={`${environment} API Key`} />

          <Link to="/#authentication" className="link inline-block mt-2 ml-4">
            Read about authentication
          </Link>
        </div>
      ) : (
        <div>No {environment} API key registered</div>
      )}
    </section>
  )
}
