import React, { useEffect, useState } from 'react'
import { Modal } from '../shared/Modal'
import { acceptTermsOfService, acceptedTermsOfService } from './termsOfServiceHelper'
import { TermsAndConditions } from './TermsAndConditions'
import { hasDeveloperPortalLicense } from '../../lib/authentication'

const noOp = () => {}

export const AcceptTermsAndConditionsDialog = () => {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState<boolean | undefined>(undefined)
  const [hasCheckedCheckbox, setHasCheckedCheckbox] = useState(false)

  useEffect(() => {
    acceptedTermsOfService().then(hasAccepted => setHasAcceptedTerms(!!hasAccepted))
  }, [])

  const acceptTerms = () => acceptTermsOfService().then(() => setHasAcceptedTerms(true))

  return (
    <Modal isOpen={hasAcceptedTerms !== undefined && !hasAcceptedTerms && hasDeveloperPortalLicense()}>
      <div className="dialog max-h-[60vh] overflow-y-scroll !rounded-b-none">
        <div className="p-6">
          <TermsAndConditions />
        </div>
      </div>
      <div className="dialog !rounded-t-none">
        <div className="p-6 flex flex-col items-start">
          <label className="flex items-center">
            <input
              type="checkbox"
              className="checkbox mr-2"
              onChange={() => setHasCheckedCheckbox(!hasCheckedCheckbox)}
              checked={hasCheckedCheckbox}
            />
            Yes, I accept these terms and conditions
          </label>

          <button
            type="button"
            className="btn primary self-end"
            aria-disabled={!hasCheckedCheckbox}
            onClick={hasCheckedCheckbox ? acceptTerms : noOp}
          >
            Accept
          </button>
        </div>
      </div>
    </Modal>
  )
}
