import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { ApiSchemaItem } from './ApiSchemaItem'
import { LayoutGroup } from 'framer-motion'
import { ApiSchemaSelector } from './ApiSchemaSelector'
import { ErrorBoundary } from '../../../shared/ErrorBoundary'

type Props = {
  level: number
  name?: string
  schema: OpenAPIV3_1.SchemaObject
}

export const ApiSchemaRecursive: FunctionComponent<Props> = ({ level, name, schema }) => {
  if (!schema) {
    return null
  }

  const properties = getSchemaProperties(schema)
  const propertyKeys = Object.keys(properties)

  const requiredFields = schema.required && schema.required.length ? schema.required : []

  return (
    <LayoutGroup id={`schema-${level}-${name}`}>
      {level === 0 ? (
        <>
          <p className="mb-6">{schema.description}</p>

          <ul>
            {propertyKeys.map((propertyKey, i) => {
              const property = properties[propertyKey]

              return (
                <ApiSchemaRecursive
                  key={`prop-${name}-${i}`}
                  name={propertyKey}
                  level={level + 1}
                  schema={addRequiredToSchemaIfRequiredInParent(property, requiredFields.indexOf(propertyKey) !== -1)}
                />
              )
            })}
          </ul>
        </>
      ) : 'items' in schema ? (
        <ErrorBoundary errorMsg={`Error rendering ${name}`}>
          <ApiSchemaItem name={name} schema={schema}>
            {hasCompositeKeywordsInItemSchema(schema.items as OpenAPIV3_1.SchemaObject) ? (
              <ul>
                <ApiSchemaItem schema={schema.items as OpenAPIV3_1.SchemaObject}>
                  <ApiSchemaSelector schema={schema.items as OpenAPIV3_1.ArraySchemaObject} />
                </ApiSchemaItem>
              </ul>
            ) : (
              <ul>
                <ApiSchemaRecursive level={level + 1} schema={schema.items as OpenAPIV3_1.SchemaObject} />
              </ul>
            )}
          </ApiSchemaItem>
        </ErrorBoundary>
      ) : propertyKeys.length > 0 ? (
        <>
          <ErrorBoundary errorMsg="Error rendering properties">
            <ApiSchemaItem name={name} schema={schema}>
              <ul>
                {propertyKeys.map((propertyKey, i) => {
                  const property = (
                    schema.properties as {
                      [name: string]: OpenAPIV3_1.SchemaObject
                    }
                  )[propertyKey]

                  return (
                    <ApiSchemaRecursive
                      key={`prop-${name}-${i}`}
                      name={propertyKey}
                      level={level + 1}
                      schema={addRequiredToSchemaIfRequiredInParent(
                        property,
                        requiredFields.indexOf(propertyKey) !== -1
                      )}
                    />
                  )
                })}
              </ul>
            </ApiSchemaItem>
          </ErrorBoundary>
        </>
      ) : (
        <ErrorBoundary errorMsg="Error rendering schema item">
          <ApiSchemaItem name={name} schema={schema as OpenAPIV3_1.SchemaObject}>
            {hasCompositeKeywordsInItemSchema(schema as OpenAPIV3_1.SchemaObject) && (
              <ApiSchemaSelector schema={schema as OpenAPIV3_1.SchemaObject} />
            )}
          </ApiSchemaItem>
        </ErrorBoundary>
      )}
    </LayoutGroup>
  )
}

const hasCompositeKeywordsInItemSchema = (schema: OpenAPIV3_1.SchemaObject) => {
  return Object.hasOwn(schema, 'oneOf') || Object.hasOwn(schema, 'anyOf')
}

const getSchemaProperties = (schema: OpenAPIV3_1.SchemaObject) => {
  if (Object.hasOwn(schema, 'properties')) {
    return schema.properties as { [name: string]: OpenAPIV3_1.SchemaObject }
  }

  return {} as { [name: string]: OpenAPIV3_1.SchemaObject }
}

const addRequiredToSchemaIfRequiredInParent = (originalSchema: OpenAPIV3_1.SchemaObject, requiredInParent: boolean) => {
  return {
    ...originalSchema,
    ...(requiredInParent ? { required: true } : {})
  } as OpenAPIV3_1.SchemaObject
}
