import React from 'react'
import { Navigate, useLocation, useRouteLoaderData } from 'react-router-dom'
import { WebhooksLoaderData } from '../../../../types'

export const WebhookIndexRoute = () => {
  const { webhooks } = useRouteLoaderData('webhooks') as WebhooksLoaderData
  const { pathname } = useLocation()

  if (webhooks.length === 0) {
    return <Navigate to={`${pathname}/create`} replace />
  }

  return <Navigate to={`${pathname}/${webhooks[0].id}`} replace />
}
