import React from 'react'
import { OAuthClients } from './OAuthClients'
import { CreateOAuthClientForm } from './CreateOAuthClientForm'
import {
  createOAuthClientAction,
  deleteOAuthClientAction,
  getOAuthClient,
  getOAuthClients
} from './oauth-client.service'
import { ErrorElement } from '../../../shared/ErrorElement'
import { DeleteOAuthClientConfirmation } from './DeleteOAuthClientConfirmation'
import { OAuthClientDetail } from './OAuthClientDetail'
import { OAuthClientsIndexRoute } from './OAuthClientsIndexRoute'
import { UnknownError } from '../../../shared/UnknownError'

export const OAuthClientRoutes = {
  id: 'oauthClients',
  path: 'oauth-clients',
  loader: getOAuthClients,
  element: <OAuthClients />,
  errorElement: <UnknownError />,
  children: [
    {
      path: 'create',
      element: <CreateOAuthClientForm />,
      action: createOAuthClientAction
    },
    {
      path: ':clientId',
      element: <OAuthClientDetail />,
      loader: getOAuthClient
    },
    {
      path: ':clientId/delete',
      element: <DeleteOAuthClientConfirmation />,
      errorElement: <ErrorElement />,
      action: deleteOAuthClientAction
    },
    {
      index: true,
      element: <OAuthClientsIndexRoute />
    }
  ]
}
