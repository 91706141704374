import React from 'react'
import { Link, NavLink, Outlet, useLoaderData } from 'react-router-dom'
import { Webhook } from '../../../../types'
import { WebhookStateIndicator } from './WebhookStateIndicator'
import { CopyTextField } from '../common/CopyTextField'

export const WebhookDetails = () => {
  const webhook = useLoaderData() as Webhook
  const { url, events, active } = webhook

  return (
    <>
      <div className="card p-6">
        <div className="flex items-center space-x-4">
          <WebhookStateIndicator isActive={active} />
          <div className="flex-1">
            <CopyTextField label="" description="URL" value={url} />
          </div>
        </div>

        <div className="mt-2 flex justify-between items-start ml-11">
          <div className="flex flex-col">
            {events.map(event => (
              <pre key={`event-${event}`} className="text-sm">
                {event}
              </pre>
            ))}
          </div>

          <div className="flex justify-end space-x-6 mt-6">
            <Link to="edit" className="btn">
              Edit
            </Link>
            <Link to="delete" className="btn danger outlined">
              Delete
            </Link>
          </div>
        </div>
      </div>

      <div className="tabs mt-8 mb-4 w-1/4">
        <NavLink to="">Events</NavLink>
        <NavLink to="notifications">Notifications</NavLink>
      </div>

      <Outlet />
    </>
  )
}
