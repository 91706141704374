import React, { useEffect, useState } from 'react'
import { useFetcher, useNavigate, useParams, useRouteLoaderData } from 'react-router-dom'
import { Modal } from '../../../shared/Modal'
import { Webhook, WebhooksLoaderData } from '../../../../types'

export const DeleteWebhookConfirmation = () => {
  const { environment, webhookId } = useParams()
  const navigate = useNavigate()
  const fetcher = useFetcher()

  const [isOpen, setIsOpen] = useState(false)

  const { webhooks } = useRouteLoaderData('webhooks') as WebhooksLoaderData
  const webhook = webhooks.find(w => w.id === webhookId) as Webhook

  const isWorking = fetcher.state === 'submitting' || fetcher.state === 'loading'

  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true)
    }, 200)
  }, [])

  return (
    <Modal isOpen={isOpen}>
      <div className="dialog">
        <div className="dialog-content">
          {isWorking ? (
            <div className="flex items-center justify-center">
              <div className="loading-infinity w-20" />
            </div>
          ) : (
            <fetcher.Form method="delete" action={`/account/${environment}/webhooks/${webhook.id}/delete`}>
              <h1 className="text-3xl">Delete webhook?</h1>
              <p className="my-8">
                The webhook with the url <span className="font-bold">{webhook.url}</span> will be permanently removed.
              </p>
              <p>This action cannot be undone.</p>

              <input type="hidden" className="hidden" name="webhookId" value={webhook.id} />

              <div className="flex justify-end space-x-6 mt-6">
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    setIsOpen(false)
                    navigate(-1)
                  }}
                >
                  No
                </button>
                <button type="submit" className="btn danger">
                  Delete
                </button>
              </div>
            </fetcher.Form>
          )}
        </div>
      </div>
    </Modal>
  )
}
