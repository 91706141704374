import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'

type Props = {
  tag: string
  pathsByTags: Record<string, OpenAPIV3_1.PathItemObject>
  children?: React.ReactNode
}

export const ApiTag: FunctionComponent<Props> = ({ tag, pathsByTags, children }) => {
  const isBeta =
    pathsByTags && pathsByTags[tag]
      ? Object.values(pathsByTags[tag]).some(path => {
          const operations = Object.values(path) as OpenAPIV3_1.OperationObject[]
          return operations.some(operation => operation.tags?.some(tag => tag.toLowerCase() === 'beta'))
        })
      : false

  return (
    <section id={encodeURI(tag.toLowerCase())} key={`section-${tag}`} className="scroll-mt-6">
      <div className="flex space-x-2 items-start">
        <h2 className="text-xl mb-2 ml-[2ch]">{tag}</h2>
        {isBeta && <span className="relative chip bg-fire-orange-lighter -mt-2">BETA</span>}
      </div>

      <div className="flex flex-col space-y-6">{children}</div>
    </section>
  )
}
