import React, { FunctionComponent, useEffect, useState } from 'react'

type Props = {
  data: any
  level: number
  open?: boolean
  name?: string | null
  expandAll: boolean
  isLast?: boolean
}

export const JSONTreeView: FunctionComponent<Props> = ({
  data,
  open = false,
  level,
  name = null,
  isLast = true,
  expandAll
}) => {
  const [isOpen, setIsOpen] = useState(() => open)
  const isDataArray = Array.isArray(data)

  useEffect(() => {
    if (!expandAll) {
      setIsOpen(false)
    } else if (expandAll) {
      setIsOpen(expandAll)
    } else {
      setIsOpen(open)
    }
  }, [open, expandAll])

  const NodeElem = () => {
    return (
      <pre>
        {name && <>{name}:</>}

        {isOpen || level === 0 ? (isDataArray ? '[' : '{') : isDataArray ? ' [ ... ]' : ' { ... }'}
      </pre>
    )
  }

  return (
    <div className={`text-[0.9rem] ${level > 0 ? 'ml-[2ch]' : ''}`}>
      {level === 0 ? (
        <NodeElem />
      ) : (
        <button onClick={() => setIsOpen(!isOpen)}>
          <span className="flex items-center space-x-[1ch]">
            <span className="inline-block w-[1ch]">{isOpen ? '-' : '+'}</span>
            <NodeElem />
          </span>
        </button>
      )}

      {isOpen || level === 0 ? (
        <>
          {Object.keys(data).map((key, i, array) => {
            return typeof data[key] === 'object' ? (
              <JSONTreeView
                key={`${name}-${key}-${i}`}
                level={level + 1}
                data={data[key]}
                isLast={i === array.length - 1}
                name={isDataArray ? null : key}
                expandAll={expandAll}
              />
            ) : (
              <pre key={`${name}-${key}-${i}`} className={'ml-[4ch]'}>
                {!isDataArray && <>{key}: </>}

                {JSON.stringify(data[key], null, 2)}

                {i === array.length - 1 ? '' : ','}
              </pre>
            )
          })}

          {isDataArray ? ']' : '}'}

          {!isLast ? ',' : ''}
        </>
      ) : null}
    </div>
  )
}
