import React, { useState } from 'react'
import { ApiProductsData, RequestStatus } from '../../types'
import { LargeScreenNavigation } from './LargeScreenNavigation'
import { useMediaQuery } from 'react-responsive'
import { BreakpointQueries } from '../../mediaQueries'
import { SmallScreenNavigation } from './SmallScreenNavigation'
import { authStore, hasDeveloperPortalLicense } from '../../lib/authentication'
import { useLoaderData } from 'react-router-dom'

export const AuthenticatedNavBar = () => {
  const apiProducts = useLoaderData() as ApiProductsData
  const [logoutStatus, setLogoutStatus] = useState<RequestStatus>('idle')

  const isLaptopAndAbove = useMediaQuery({ query: BreakpointQueries.Laptop })
  const hasAccess = hasDeveloperPortalLicense()

  const doLogout = async () => {
    try {
      setLogoutStatus('pending')
      await authStore.logout()
    } catch (error) {
      setLogoutStatus('failure')
    }
  }

  return isLaptopAndAbove ? (
    <LargeScreenNavigation
      apiProducts={apiProducts}
      logoutStatus={logoutStatus}
      hasAccess={hasAccess}
      logout={doLogout}
    />
  ) : (
    <SmallScreenNavigation
      apiProducts={apiProducts}
      logoutStatus={logoutStatus}
      hasAccess={hasAccess}
      logout={doLogout}
    />
  )
}
