import React from 'react'
import { OAuthClientRoutes } from './oauth-clients/oauth-client.routes'
import { WebhookRoutes } from './webhooks/webhook.routes'
import { ErrorElement } from '../../shared/ErrorElement'
import { AccountPage } from './AccountPage'
import { UnknownErrorWithReload } from '../../shared/UnknownErrorWithReload'
import { AccountOverview } from './AccountOverview'
import { Navigate } from 'react-router-dom'
import { getAccount } from '../../../services/account-service'

export const AccountRoutes = {
  id: 'account',
  path: 'account',
  loader: getAccount,
  element: <AccountPage />,
  errorElement: <UnknownErrorWithReload reloadPath="/account" />,
  children: [
    {
      path: ':environment',
      errorElement: <ErrorElement />,
      children: [
        {
          path: '',
          element: <AccountOverview />
        },
        OAuthClientRoutes,
        WebhookRoutes
      ]
    },
    {
      index: true,
      element: <Navigate to="/account/sandbox" replace />
    }
  ]
}
