import { OpenAPIV3_1 } from 'openapi-types'
import { isDate } from 'date-fns'

export const parseOpenApiSpec = (json: OpenAPIV3_1.Document) => {
  const components = json.components as OpenAPIV3_1.ComponentsObject

  return Object.entries(json).reduce((acc, entry) => {
    const [key, value] = entry

    if (key === 'paths') {
      return { ...acc, paths: replaceRef(value, components) }
    }

    if (key === 'webhooks') {
      return { ...acc, webhooks: replaceRef(value, components) }
    }

    return acc
  }, json) as OpenAPIV3_1.Document
}

const replaceRef = (obj: any, components: OpenAPIV3_1.ComponentsObject): any => {
  if (isDate(obj)) {
    return obj.toISOString()
  }

  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(item => replaceRef(item, components))
  }

  if ('$ref' in obj) {
    return replaceRef(mapRefToSchema(obj.$ref, components), components)
  }

  if ('allOf' in obj) {
    const allOfProperties = obj.allOf.reduce(
      (
        acc: Record<string, OpenAPIV3_1.SchemaObject>,
        value: OpenAPIV3_1.SchemaObject | OpenAPIV3_1.ReferenceObject
      ) => {
        if ('$ref' in value) {
          const mappedProperties = replaceRef(mapRefToSchema(value.$ref, components), components)?.properties
          return { ...acc, ...mappedProperties }
        }

        return { ...acc, ...value.properties }
      },
      {} as Record<string, OpenAPIV3_1.SchemaObject>
    )

    return { ...obj, properties: { ...allOfProperties, ...obj.properties } }
  }

  return Object.keys(obj).reduce(
    (acc, key) => {
      if (typeof obj[key] == 'boolean' && key === 'example') {
        acc[key] = obj[key].toString()
      } else {
        acc[key] = replaceRef(obj[key], components)
      }

      return acc
    },
    {} as { [key: string]: any }
  )
}

const mapRefToSchema = (ref: string, components: OpenAPIV3_1.ComponentsObject) => {
  if (!ref) {
    return {}
  }

  const isSchema = ref.includes('/schemas/')

  if (isSchema) {
    const schemaName = ref.replace('#/components/schemas/', '')
    const schemas = components.schemas as Record<string, OpenAPIV3_1.SchemaObject>

    return { ...schemas[schemaName], title: schemaName }
  }

  const isResponse = ref.includes('/responses/')

  if (isResponse) {
    const responseName = ref.replace('#/components/responses/', '')
    const responses = components.responses as Record<string, OpenAPIV3_1.ResponseObject>
    return { ...responses[responseName], title: responseName }
  }

  const isExample = ref.includes('/examples/')

  if (isExample) {
    const exampleName = ref.replace('#/components/examples/', '')
    const examples = components.examples as Record<string, OpenAPIV3_1.ExampleObject>
    return { ...examples[exampleName], title: exampleName }
  }

  return null
}
