import React from 'react'
import {
  createWebhookAction,
  deleteWebhookAction,
  webhookLoader,
  updateWebhookAction,
  webhooksLoader,
  activateWebhookAction,
  webhookNotificationLoader,
  webhookNotificationActions
} from './webhook.service'
import { Webhooks } from './Webhooks'
import { ErrorElement } from '../../../shared/ErrorElement'
import { DeleteWebhookConfirmation } from './DeleteWebhookConfirmation'
import { WebhookDetails } from './WebhookDetails'
import { WebhookIndexRoute } from './WebhooksIndexRoute'
import { UnknownError } from '../../../shared/UnknownError'
import { CreateWebhook } from './CreateWebhook'
import { EditWebhook } from './EditWebhook'
import { WebhookHistory } from './history/WebhookHistory'
import { WebhookHistoryErrorElement } from './history/WebhookHistoryErrorElement'
import { WebhookNotifications } from './notifications/WebhookNotifications'

export const WebhookRoutes = {
  id: 'webhooks',
  path: 'webhooks',
  loader: webhooksLoader,
  element: <Webhooks />,
  errorElement: <UnknownError />,
  children: [
    {
      index: true,
      element: <WebhookIndexRoute />
    },
    {
      path: 'create',
      element: <CreateWebhook />,
      action: createWebhookAction
    },
    {
      path: ':webhookId/edit',
      element: <EditWebhook />,
      action: updateWebhookAction
    },
    {
      id: 'webhook',
      path: ':webhookId',
      element: <WebhookDetails />,
      loader: webhookLoader,
      action: activateWebhookAction,
      errorElement: <ErrorElement />,
      children: [
        {
          index: true,
          element: <WebhookHistory />,
          errorElement: <WebhookHistoryErrorElement />
        },
        {
          path: 'delete',
          element: <DeleteWebhookConfirmation />,
          action: deleteWebhookAction,
          errorElement: <ErrorElement />
        },
        {
          path: 'notifications',
          loader: webhookNotificationLoader,
          action: webhookNotificationActions,
          element: <WebhookNotifications />,
          errorElement: <ErrorElement />
        }
      ]
    }
  ]
}
